<div class="time-input-container input-wrapper" [attr.data-symbol]="format" fxFlex>
  <div class="h-100-p" fxLayout="row" fxLayoutAlign="start center">
    @if (withDays) {
      <input
        #daysInput
        type="number"
        [ngModel]="days | pad: 2:'0'"
        (ngModelChange)="onChange($event, 'days')"
        class="time-input"
        style="-webkit-appearance: textfield"
        min="0"
      />
      <div class="time-divider">:</div>
    }

    <input
      #hoursInput
      type="number"
      [ngModel]="hours | pad: 2: '0'"
      (ngModelChange)="onChange($event, 'hours')"
      class="time-input"
      style="-webkit-appearance: textfield"
      [max]="withDays ? 23 : null"
      min="0"
    />

    <div class="time-divider">:</div>

    <input
      #minutesInput
      type="number"
      [ngModel]="minutes | pad: 2:'0'"
      (ngModelChange)="onChange($event, 'minutes')"
      class="time-input"
      style="-webkit-appearance: textfield"
      max="59"
      min="0"
    />
  </div>
</div>
